// @flow

import React from 'react';

const HomeIllustration = (): React$Element<'picture' | 'img'> => {
  const sizes = '(max-width: 767px) 288px, (max-width: 1023px) 366px, 523px';
  return (
    <picture>
      <source
        type="image/webp"
        srcSet="
          /images/astro-small@2x.webp 576w,
          /images/astro-medium@2x.webp 732w,
          /images/astro-large@2x.webp 1046w"
        sizes={sizes}
      />
      <source
        media="(min-resolution: 144dpi),(-webkit-min-device-pixel-ratio: 1.5)"
        srcSet="
          /images/astro-small@2x.png 576w,
          /images/astro-medium@2x.png 732w,
          /images/astro-large@2x.png 1046w"
        sizes={sizes}
      />
      <source
        srcSet="
          /images/astro-small.png 288w,
          /images/astro-medium.png 366w,
          /images/astro-large.png 523w"
        sizes={sizes}
      />
      <img
        src="/images/astro-large.png"
        alt="Hanno Astronaut"
        width="523"
        height="523"
      />
    </picture>
  );
};

export default HomeIllustration;
