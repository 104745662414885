// @flow

import React from 'react';
import styled from 'styled-components';

import ArrowRight from 'assets/arrowRight.svg';
import {mediaQueries, themeColors} from 'theme';

import Link from './Link';

type Props = {
  label: string,
  linkTo: string,
  ariaLabel: string,
  testId: string,
  icon?: boolean,
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  background-color: ${themeColors.primary};
  padding: 0.625rem 1rem;
  border-radius: 2px;
  transition: all 0.125s cubic-bezier(0.42, 0, 0.34, 1);

  ${mediaQueries.MIN_MD} {
    font-size: 18px;
  }

  span {
    color: #fff;
  }

  span {
    margin: 0 0.5rem 0 0;
    padding: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    svg {
      transform: translate(3px, -1px);
    }
  }

  svg {
    transform: translate(0, -1px);
    transition: transform 0.125s cubic-bezier(0.42, 0, 0.34, 1);
  }

  ${mediaQueries.MAX_SM} {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const Button = (props: Props): React$Element<'Link'> => {
  const {icon = false, label, linkTo, testId, ariaLabel} = props;

  return (
    <StyledLink to={linkTo} data-testid={testId} ariaLabel={ariaLabel}>
      <span className="fontSecondary blockcaps">{label}</span>
      {icon && <ArrowRight />}
    </StyledLink>
  );
};

export default Button;
