// @flow
import {graphql} from 'gatsby';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import Button from 'components/Button';
import Header from 'components/Header';
import HomeIllustration from 'components/HomeIllustration';
import Layout from 'components/Layout';
import Link from 'components/Link';
import {mediaQueries, themeColors} from 'theme';
import {getPageList} from 'utils/pageNavigators';
import {rhythm} from 'utils/typography';

import strings from '../config/strings.json';

const ILLUSTRATION_SIZE = 523;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    padding: 0 0 ${rhythm(1 / 2)};
    ${mediaQueries.MIN_MD}, ${mediaQueries.MAX_IPHONEX_LANDSCAPE_PARTIAL} {
      padding: 0;
    }
    ${mediaQueries.MOBILE_LANDSCAPE_LARGE} {
      padding: ${rhythm(1 / 2)} 0;
    }
  }

  &:not(:first-of-type) {
    background: #fff;
  }
`;

const Row = styled.div`
  &.divider {
    ${mediaQueries.MAX_SM} {
      border-top: 1px solid ${themeColors.black20};
    }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .colLeft,
  .colRight {
    padding: ${rhythm(3 / 5)};

    ${mediaQueries.MIN_MD}, ${mediaQueries.MAX_IPHONEX_LANDSCAPE_PARTIAL} {
      padding: ${rhythm(3 / 2)} 0;
    }
    ${mediaQueries.MIN_LG} {
      padding: ${rhythm(5 / 2)} 0 ${rhythm(2)};
    }
  }

  ${mediaQueries.MAX_SM} {
    .colLeft {
      padding-bottom: 0;
    }
    .colRight {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 960px) and (orientation: landscape) {
    .colRight,
    .colLeft {
      padding: ${rhythm(2 / 3)} 3vw;
    }
  }

  section:last-of-type &:last-of-type {
    ${mediaQueries.MIN_MD} {
      .colLeft,
      .colRight {
        padding-top: 0;
      }
    }
  }

  ${mediaQueries.MIN_MD}, ${mediaQueries.MAX_IPHONEX_LANDSCAPE_PARTIAL} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(${rhythm(1)} + 1194px);

    section:not(:first-of-type) & {
      align-items: flex-start;
    }
  }

  ${mediaQueries.MAX_IPHONEX_LANDSCAPE_FULL} {
    padding: 0;
  }

  h1 {
    ${mediaQueries.MAX_MD} {
      max-width: 400px;
    }
  }

  h1,
  h3,
  a {
    margin: ${rhythm(1 / 3)} 0;
    ${mediaQueries.MIN_LG} {
      margin: ${rhythm(1 / 2)} 0;
    }
    ${mediaQueries.MOBILE_LANDSCAPE_LARGE} {
      margin: ${rhythm(1 / 3)} 0;
    }
  }

  h1 {
    margin-top: 0;
  }

  ${mediaQueries.MAX_SM} {
    h1 {
      margin-bottom: 0;
      max-width: 300px;
    }
    h3 {
      font-size: 0.8333333333rem;
    }
  }
  ${mediaQueries.MOBILE_LANDSCAPE_LARGE} {
    h1 {
      margin-bottom: 0;
    }
    h3 {
      font-size: 0.8333333333rem;
    }
  }
  ${mediaQueries.MD_ONLY} {
    h3 {
      font-size: 1rem;
    }
  }

  a {
    margin-bottom: 0;
    ${mediaQueries.MAX_SM} {
      margin-top: ${rhythm(1 / 3)};
    }
  }

  img {
    height: auto;
    margin: ${rhythm(1 / 2)} 0 0;
    object-fit: scale-down;
    object-position: left;

    ${mediaQueries.MAX_SM} {
      max-height: ${ILLUSTRATION_SIZE / 2}px;
    }
    ${mediaQueries.MIN_MD}, ${mediaQueries.MAX_IPHONEX_LANDSCAPE_PARTIAL} {
      max-width: ${ILLUSTRATION_SIZE / 2}px;
      margin: 0;
    }
    ${mediaQueries.MIN_LG} {
      max-width: ${ILLUSTRATION_SIZE}px;
      width: 100%;
    }
  }

  .colLeft {
    ${mediaQueries.MIN_MD} {
      width: ${ILLUSTRATION_SIZE / 2}px;
    }
    ${mediaQueries.MAX_IPHONEX_LANDSCAPE_FULL} {
      display: none;
    }
    ${mediaQueries.MD_ONLY} {
      text-align: center;
    }
    ${mediaQueries.MIN_LG} {
      width: ${ILLUSTRATION_SIZE / 3}px;
      margin: 0 ${ILLUSTRATION_SIZE / 6}px;
      &.illustration {
        width: ${(ILLUSTRATION_SIZE / 3) * 2}px;
        margin: 0;
      }
    }
    @media screen and (min-width: 1280px) {
      min-width: calc(${ILLUSTRATION_SIZE}px / 5 * 3);
      margin-left: calc(${ILLUSTRATION_SIZE}px / 5 * 2);
      margin-right: 0;
      &.illustration {
        min-width: ${ILLUSTRATION_SIZE}px;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .blockcaps {
      display: block;
      font-size: 14px;
      font-weight: 500;

      ${mediaQueries.MAX_SM} {
        margin: ${rhythm(1 / 3)} 0 ${rhythm(2 / 3)};
      }
      ${mediaQueries.MIN_MD}, ${mediaQueries.MAX_IPHONEX_LANDSCAPE_PARTIAL} {
        transform: translate(2px, 6px);
      }
      ${mediaQueries.MIN_LG} {
        text-align: right;
        transform: translate(-10px, 9px);
      }
      @media screen and (min-width: 1280px) {
        transform: translate(-50%, 9px);
      }
    }
  }

  .colRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    ${mediaQueries.MIN_MD} {
      width: 60vw;
    }
    ${mediaQueries.MIN_LG} {
      max-width: 660px;
    }
  }
`;

const ContentList = styled.nav`
  h2 {
    margin: 0;
    font-weight: 400;
  }

  .chapterLink {
    text-decoration: none;
    display: block;
    margin: 0 0 ${rhythm(1 / 3)};
    color: ${themeColors.textSubtle};
    cursor: pointer;
    transition: all 0.125s cubic-bezier(0.42, 0, 0.34, 1);

    &:hover,
    &:focus {
      color: ${themeColors.textLinkSecondary};
    }

    ${mediaQueries.MIN_MD} {
      &:first-of-type {
        margin-top: -9px;
      }
    }
  }
`;

type Props = {
  data: Object,
  location: Object,
  pageContext: Object,
};

const PageHome = (props: Props): React$Element<typeof Layout> => {
  const {data, location} = props;

  const {title, description, action, introduction, contents} = strings.pages[
    'home'
  ];

  const sortedPages = getPageList(data.allMarkdownRemark);
  const currentPath = location.pathname;

  return (
    <Layout path={currentPath}>
      <Section>
        <Row>
          <div className="colLeft illustration">
            <HomeIllustration />
          </div>
          <div className="colRight">
            <Header title={title} subtitle={description} />
            <Button
              icon
              linkTo="/research"
              label={action}
              testId="beginReadingButton"
              ariaLabel="Go to Chapter 1 of Playbook"
            />
          </div>
        </Row>
      </Section>
      <Section>
        <Row>
          <div className="colLeft">
            <span className="fontSecondary blockcaps">
              {introduction.header}
            </span>
          </div>
          <div className="colRight">
            <ReactMarkdown linkTarget="_blank">
              {introduction.content}
            </ReactMarkdown>
          </div>
        </Row>
        <Row className="divider">
          <div className="colLeft">
            <span className="fontSecondary blockcaps">{contents.header}</span>
          </div>
          <div className="colRight">
            <ContentList role="navigation">
              {sortedPages.map((page) => {
                return (
                  <Link
                    key={page.id}
                    data-testid={`homepageLink-${page.id}`}
                    to={page.url}
                    className="chapterLink">
                    <h2>{page.title}</h2>
                  </Link>
                );
              })}
            </ContentList>
          </div>
        </Row>
      </Section>
    </Layout>
  );
};

export default PageHome;

// IMPORTANT: If you change this query, be sure to also modify the matching version in gatsby-node.esm.js
export const query = graphql`
  query PageQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            order
            title
            slug
            summary
          }
          parent {
            ... on File {
              name
              relativePath
            }
          }
        }
      }
    }
  }
`;
